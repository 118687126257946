import { makeTranslations } from '../../utils/translations';

export const useTrans = makeTranslations({
  en_US: {
    title: "Privacy policy",
  },
  de_DE: {
    title: "Datenschutz-Bestimmungen",
  },
  pl_PL: {
    title: "Polityka prywatności",
  }
});
