// Dependencies scoped imports
import React, { useRef } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

// Project scoped imports

// Module scoped imports
import { useTrans } from "./trans";
import { StyledRoot } from "./styles";
import { PrivacyPolicyDialogProps } from "./types";
import { useNavigate } from "react-router-dom";

const PP_FILE = "privacy-policy.pdf";
const PP_PATH = "documents";
const PP_URL = `${window.location.origin}/${PP_PATH}/${PP_FILE}`;

/**
 * @description A dialog displaying the privacy policy PDF file.
 *
 * API:
 *
 * - inherits [Dialog API](https://material-ui.com/api/dialog/)
 */
const PrivacyPolicyDialog = ({
  sx,
  classes,
  open,
}: PrivacyPolicyDialogProps) => {
  const contentRef = useRef<HTMLCanvasElement>(null);
  // const isViewSmall = useMediaQuery("(max-width:707px)");
  let navigate = useNavigate();
  const translated = useTrans();

  const onClose = () => {
    navigate("/");
  };

  return (
    <>
      <StyledRoot
        sx={sx}
        className={classes?.root}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogContent
          ref={contentRef}
          style={{ position: "relative", display: "block", height: window.innerHeight - 200 }}
        >
          <object data={PP_URL} type="application/pdf" width="100%" height="100%" style={{ position: "absolute", top: 0, left: 0 }}>
            <Stack alignItems="center" justifyContent="center" height="100%" paddingX={1}>
              <Typography align="center">Twoja przeglądarka internetowa nie ma wtyczki PDF.</Typography>
              <Typography align="center">Możesz kliknąć poniżej, aby wydrukować lub pobrać plik.</Typography>

              <Stack direction="row" alignItems="center" spacing={3} marginTop={4}>
                <IconButton href={PP_URL} download="privacyPolicy.pdf">
                  <Icon>download</Icon>
                </IconButton>
                <IconButton href={PP_URL} target="_blank">
                  <Icon>print</Icon>
                </IconButton>
              </Stack>
            </Stack>
          </object>


        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{translated.close}</Button>
        </DialogActions>
      </StyledRoot>
    </>
  );
};

export default PrivacyPolicyDialog;
